import * as EVENT from '@utilities/constants/triggerKeys';
import { form } from '@utilities/helpers/getFormSections';
import _ from 'lodash';
import { ObjectId } from 'bson';
import { amountCurrencyFormatter } from '@utilities/helpers/formatter';

const showHosCbAttachRptUpld = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {
        name[0].groups[3].isVisible = true;
        name[0].groups[4].isVisible = true;
        name[0].groups[5].isVisible = false;
        name[1].isVisible = false;
        name[2].isVisible = false;
        name[3].isVisible = false;
        name[4].isVisible = false;
        name[5].isVisible = false;
    }
    else {
        name[0].groups[3].isVisible = false;
        name[0].groups[4].isVisible = false;
        name[0].groups[5].isVisible = true;
        name[1].isVisible = true;
        name[2].isVisible = true;
        // name[3].isVisible = true;
        name[4].isVisible = true;
        name[5].isVisible = true;

    }
}
const showCommunityBuildingAct = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[3].isVisible = event === EVENT.KEY_YES;
};

const showHosFaPolAppDsc = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[2].groups[1].isVisible = event === EVENT.KEY_YES;
};

const showHosFaIncmLmtFree = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[2].groups[3].isVisible = event === EVENT.KEY_YES;

    if (event && name[2].groups[3].fields[1].default === "Other") {
        name[2].groups[4].isVisible = event === "Yes" ? true : false;
    }
};

const showHosFaIncmLmtDisc = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[2].groups[6].isVisible = event === EVENT.KEY_YES;
    name[2].groups[8].isVisible = event === EVENT.KEY_YES;
    name[2].groups[9].isVisible = event === EVENT.KEY_YES;

    if (event && name[2].groups[6].fields[1].default === "Other") {
        name[2].groups[7].isVisible = event === "Yes" ? true : false;
    }
};


const showHosFaComServDesc = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[2].groups[14].isVisible = event === EVENT.KEY_YES;
    name[2].groups[15].isVisible = event === EVENT.KEY_YES;
    name[2].groups[16].isVisible = event === EVENT.KEY_YES;
};

const showHosFaAffilSysRole = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[2].groups[18].isVisible = event === EVENT.KEY_YES;
    name[2].groups[19].isVisible = event === EVENT.KEY_YES;
};

const showAcquisitionDetails = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[0].groups[2].isVisible = event === EVENT.KEY_YES;
}

const calculateLastCHNA = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections && Object.keys(formSections).length > 0 ? formSections : sections;
    const internalSetup = form.formSections;
    name[0].groups[4].fields[1].default = '';
    name[0].groups[4].fields[1].value = '';
    name[0].groups[5].fields[1].default = '';
    name[0].groups[21].isVisible = false;
    name[0].groups[22].isVisible = false;
    // name[0].groups[23].isVisible = false;
    // name[0].groups[24].isVisible = false;
    // name[0].groups[25].isVisible = false;
    // name[0].groups[26].isVisible = false;
    // name[0].groups[27].isVisible = false;
    // name[0].groups[28].isVisible = false;
    name[0].groups[29].isVisible = false;
    name[0].groups[30].isVisible = false;
    name[0].groups[31].isVisible = false;
    name[0].groups[32].isVisible = false;
    name[0].groups[33].isVisible = false;
    name[0].groups[34].isVisible = false;
    name[0].groups[35].isVisible = false;
    name[0].groups[36].isVisible = false;
    name[0].groups[37].isVisible = false;
    name[0].groups[38].isVisible = false;
    name[0].groups[39].isVisible = false;
    name[0].groups[40].isVisible = false;
    if (internalSetup && internalSetup[0].groups[14].fields[1]?.value?.trim() && event?.trim()) {
        const lastCHNA = internalSetup[0].groups[14].fields[1].value - event;
        if (lastCHNA >= 0 && lastCHNA < 100) {
            name[0].groups[4].fields[1].default = lastCHNA.toString();
            name[0].groups[4].fields[1].value = lastCHNA.toString();
        }
        if (lastCHNA < 3 && lastCHNA >= 0) {
            name[0].groups[5].fields[1].default = EVENT.KEY_YES;
            name[0].groups[21].isVisible = true;
            name[0].groups[22].isVisible = true;
            // name[0].groups[23].isVisible = true;
            // name[0].groups[24].isVisible = true;
            // name[0].groups[25].isVisible = true;
            // name[0].groups[26].isVisible = true;
            // name[0].groups[27].isVisible = true;
            // name[0].groups[28].isVisible = true;
            name[0].groups[29].isVisible = true;
            name[0].groups[30].isVisible = true;
            name[0].groups[31].isVisible = true;
            name[0].groups[32].isVisible = true;
            name[0].groups[33].isVisible = true;
            name[0].groups[34].isVisible = true;
            name[0].groups[35].isVisible = true;
            name[0].groups[36].isVisible = true;
            name[0].groups[37].isVisible = true;
            name[0].groups[38].isVisible = true;
            name[0].groups[39].isVisible = true;
            if(name[0].groups[39].fields[1].default){
                name[0].groups[40].isVisible = true;
            }
        } else if (lastCHNA >= 0) {
            name[0].groups[5].fields[1].default = EVENT.KEY_NO;
        } else {
            name[0].groups[5].fields[1].default = ''
        }
    }
    let lastCHNADefault = name[0].groups[5].fields[1].default;
    if (lastCHNADefault === 'Yes'){
        name[0].groups[6].isVisible = true;
        name[0].groups[8].isVisible = true;
        name[0].groups[10].isVisible = true;
        name[0].groups[12].isVisible = true;
        if(name[0].groups[6].isVisible && lastCHNADefault === 'Yes' &&  name[0].groups[6].fields[1].default){
            name[0].groups[7].isVisible = true;
        }
        if(name[0].groups[8].isVisible && lastCHNADefault === 'Yes' && name[0].groups[8].fields[1].default){
            name[0].groups[9].isVisible = true;
        }
        if(name[0].groups[10].isVisible && lastCHNADefault === 'Yes' && name[0].groups[10].fields[1].default){
            name[0].groups[11].isVisible = true;
        }
        if(name[0].groups[12].isVisible && lastCHNADefault === 'Yes' && name[0].groups[12].fields[1].default){
            name[0].groups[13].isVisible = true;
            name[0].groups[14].isVisible = true;
            name[0].groups[16].isVisible = true;
            name[0].groups[18].isVisible = true;
            name[0].groups[19].isVisible = true;
            if (name[0].groups[14].isVisible && name[0].groups[14].fields[1].default){
                name[0].groups[15].isVisible = true;
            }
            if (name[0].groups[16].isVisible && name[0].groups[16].fields[1].default){
                name[0].groups[17].isVisible = true;
            }
            if (name[0].groups[19].isVisible && name[0].groups[19].fields[1].default){
                name[0].groups[20].isVisible = true;
            }
        }
    } else {
        name[0].groups[6].isVisible = false;
        name[0].groups[8].isVisible = false;
        name[0].groups[10].isVisible = false;
        name[0].groups[12].isVisible = false;
        if(!name[0].groups[6].isVisible && (lastCHNADefault === 'No' || lastCHNADefault === '' ) && name[0].groups[6].fields[1].default){
            name[0].groups[7].isVisible = false;
        }
        if(!name[0].groups[8].isVisible && (lastCHNADefault === 'No' || lastCHNADefault === '' ) && name[0].groups[8].fields[1].default){
            name[0].groups[9].isVisible = false;
        }
        if(!name[0].groups[10].isVisible && (lastCHNADefault === 'No' || lastCHNADefault === '' ) && name[0].groups[10].fields[1].default){
            name[0].groups[11].isVisible = false;
        }
        if(!name[0].groups[12].isVisible && (lastCHNADefault === 'No' || lastCHNADefault === '' ) && name[0].groups[12].fields[1].default){
            name[0].groups[13].isVisible = false;
            name[0].groups[14].isVisible = false;
            name[0].groups[16].isVisible = false;
            name[0].groups[18].isVisible = false;
            name[0].groups[19].isVisible = false;
            if (!name[0].groups[14].isVisible && name[0].groups[14].fields[1].default){
                name[0].groups[15].isVisible = false;
            }
            if (!name[0].groups[16].isVisible && name[0].groups[16].fields[1].default){
                name[0].groups[17].isVisible = false;
            }
            if (!name[0].groups[19].isVisible && name[0].groups[19].fields[1].default){
                name[0].groups[20].isVisible = false;
            }
        }
    }
}

const showMedicareRevenueCosts = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_YES) {
        name[4].groups[7].isVisible = true;
        name[4].groups[8].isVisible = true;
        name[4].groups[9].isVisible = true;
        if(name[4].groups[9].fields[1].default === 'Other'){
            name[4].groups[10].isVisible = true;
        }else{
            name[4].groups[10].isVisible = false;
        }
        name[4].groups[11].isVisible = true;
        name[4].groups[12].isVisible = true;
    } else {
        name[4].groups[7].isVisible = false;
        name[4].groups[8].isVisible = false;
        name[4].groups[9].isVisible = false;
        name[4].groups[10].isVisible = false;
        name[4].groups[11].isVisible = false;
        name[4].groups[12].isVisible = false;
    }
};

const showhosDbtMcRevMthdOther = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (event === 'Other') {
        name[4].groups[10].isVisible = true;
    } else {
        name[4].groups[10].isVisible = false;
    }
}

const showWrittenDebtCollectionPolicy = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_YES) {
        name[4].groups[14].isVisible = true;
        name[4].groups[15].isVisible = true;
    } else {
        name[4].groups[14].isVisible = false;
        name[4].groups[15].isVisible = false;
    }
};

const showhosChnaSpecKnow = (event, options) =>{
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {
        name[0].groups[7].isVisible = true;
    } else {
        name[0].groups[7].isVisible = false;
    }
}

const showhosChnaMany = (event, options) =>{
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {
        name[0].groups[9].isVisible = true;
    } else {
        name[0].groups[9].isVisible = false;
    }
}

const showhosChnaManyNonHspt = (event, options) =>{
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {
        name[0].groups[11].isVisible = true;
    } else {
        name[0].groups[11].isVisible = false;
    }
}

const showhosChnaPublicAvail = (event, options) =>{
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {
        name[0].groups[13].isVisible = true;
        name[0].groups[14].isVisible = true;
        name[0].groups[16].isVisible = true;
        name[0].groups[18].isVisible = true;
        name[0].groups[19].isVisible = true;
        if ( name[0].groups[14].isVisible && name[0].groups[14].fields[1].default){
            name[0].groups[15].isVisible = true;
        }
        if ( name[0].groups[16].isVisible && name[0].groups[16].fields[1].default){
            name[0].groups[17].isVisible = true;
        }
        if ( name[0].groups[19].isVisible && name[0].groups[19].fields[1].default){
            name[0].groups[20].isVisible = true;
        }
    } else {
        name[0].groups[13].isVisible = false;
        name[0].groups[14].isVisible = false;
        name[0].groups[16].isVisible = false;
        name[0].groups[18].isVisible = false;
        name[0].groups[19].isVisible = false;
        if (!name[0].groups[14].isVisible && name[0].groups[14].fields[1].default){
            name[0].groups[15].isVisible = false;
        }
        if (!name[0].groups[16].isVisible && name[0].groups[16].fields[1].default){
            name[0].groups[17].isVisible = false;
        }
        if (!name[0].groups[19].isVisible && name[0].groups[19].fields[1].default){
            name[0].groups[20].isVisible = false;
        }
    }
}

const showhosChnaHosWeb = (event, options) =>{
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (event) {
        name[0].groups[15].isVisible = true;
    } else {
        name[0].groups[15].isVisible = false;
    }
}

const showhosChnaOthWeb = (event, options) =>{
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (event) {
        name[0].groups[17].isVisible = true;
    } else {
        name[0].groups[17].isVisible = false;
    }
}

const showhosChnaOther1Desc = (event, options) =>{
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (event) {
        name[0].groups[20].isVisible = true;
    } else {
        name[0].groups[20].isVisible = false;
    }
}

const calculateSummaryCHNA = (options) => {
    const { parentGroup, parentIndex, field } = options;
    const name1 = parentGroup.entities[parentIndex].sections;
    const internalSetup = form.formSections;
    let hosChnaYrCHNA = '', hosChnaYrCHNAcalc = '-';
    name1.forEach((section, index) => {
        if (index === 0) {
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if ((field1.name === 'hosChnaYrCHNA' && field1.default) || (field.name === 'hosChnaYrCHNA' && field1.name === 'hosChnaYrCHNA')) {
                        hosChnaYrCHNA = field.name === 'hosChnaYrCHNA' ? field.default : field1.default;
                    }
                    if ((field1.name === 'hosChnaYrCHNAcalc') || (field.name === 'hosChnaYrCHNAcalc' && field1.name === 'hosChnaYrCHNAcalc')) {
                        if (internalSetup && internalSetup[0].groups[14].fields[1].value && hosChnaYrCHNA) {
                            const lastCHNA = internalSetup[0].groups[14].fields[1].value - hosChnaYrCHNA;
                            if (lastCHNA && lastCHNA >= 0 && lastCHNA < 100) {
                                hosChnaYrCHNAcalc = lastCHNA;
                            }
                        }
                    }
                })
            })
        }
    });

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if ((lineItemField.name === 'aboutHospital') && ('hosChnaYrCHNA' === field.name)) {
            lineItemField.default = `Most recently conducted a CHNA: ${hosChnaYrCHNA}\nNumber of years since last CHNA: ${hosChnaYrCHNAcalc}`;
        }
    });
}

const calculateSummaryError = (options) => {
    const { parentGroup, parentIndex, field } = options;
    const name1 = parentGroup.entities[parentIndex].sections;
    const internalSetup = form.formSections;
    let hosChnaYrCHNA = '';

    name1.forEach((section, index) => {
        if (index === 0) {
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if ((field1.name === 'hosChnaYrCHNA' && field1.default) || (field.name === 'hosChnaYrCHNA' && field1.name === 'hosChnaYrCHNA')) {
                        hosChnaYrCHNA = field.name === 'hosChnaYrCHNA' ? field.default : field1.default;
                        let InternalYear = internalSetup[0].groups[14].fields[1].value;
                        let yearDiff = Number(InternalYear) - Number(hosChnaYrCHNA);
                        if ((InternalYear !== ' ' && InternalYear !== undefined) && (hosChnaYrCHNA !== ' ' && hosChnaYrCHNA !== undefined)) {
                            if (yearDiff > 99 || yearDiff < 0) {
                                field.error = true;
                                field.errorMessage = `Please enter a year between ${Number(InternalYear) - 99} and ${Number(InternalYear)}`;
                            } else {
                                field.error = false;
                                field.errorMessage = null;
                            }
                        } else {
                            field.error = false;
                            field.errorMessage = null;
                        }
                    }
                })
            })
        }
    });
}

const showHospitalNameInSummary = (options) => {
    const { parentGroup, parentIndex, field, allSections, fns } = options;
    const name1 = parentGroup.entities[parentIndex].sections;

    let hospitalName = '';
    let hospitalWebsite = '';
    let hospitalAddress = '';
    let hospitalCountry = '';
    let hospitalCity = '';
    let hospitalStateUS = '';
    let hospitalStateCA = '';
    let hospitalStateOthers = '';
    name1.forEach((section, index) => {
        if (index === 0) {
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if ((field1.name === 'hosFacHosName' && field1.default) || (field.name === 'hosFacHosName' && field1.name === 'hosFacHosName')) {
                        hospitalName = field.name === 'hosFacHosName' ? field.default : field1.default
                    } else if ((field1.name === 'hosFacHosWeb' && field1.default) || (field.name === 'hosFacHosWeb' && field1.name === 'hosFacHosWeb')) {
                        hospitalWebsite = field.name === 'hosFacHosWeb' ? field.default : field1.default
                    } else if ((field1.name === 'hosFacHosAdd' && field1.default) || (field.name === 'hosFacHosAdd' && field1.name === 'hosFacHosAdd')) {
                        hospitalAddress = field.name === 'hosFacHosAdd' ? field.default : field1.default
                    } else if ((field1.name === 'hospFacHosCntry' && field1.default) || (field.name === 'hospFacHosCntry' && field1.name === 'hospFacHosCntry')) {
                        hospitalCountry = field.name === 'hospFacHosCntry' ? field.default : field1.default
                    } else if ((field1.name === 'hosFacHosCity' && field1.default) || (field.name === 'hosFacHosCity' && field1.name === 'hosFacHosCity')) {
                        hospitalCity = field.name === 'hosFacHosCity' ? field.default : field1.default
                    }

                    if ((field1.name === 'hosFacHosState' && field1.default) || (field.name === 'hosFacHosState' && field1.name === 'hosFacHosState')) {
                        hospitalStateUS = field.name === 'hosFacHosState' ? field.default : field1.default
                    }
                    if ((field1.name === 'hospitalOtherProv' && field1.default) || (field.name === 'hospitalOtherProv' && field1.name === 'hospitalOtherProv')) {
                        hospitalStateOthers = field.name === 'hospitalOtherProv' ? field.default : field1.default
                    }
                    if ((field1.name === 'hospitalSaleProv' && field1.default) || (field.name === 'hospitalSaleProv' && field1.name === 'hospitalSaleProv')) {
                        hospitalStateCA = field.name === 'hospitalSaleProv' ? field.default : field1.default
                    }
                })
            })
        }
    });

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if ((lineItemField.name === 'hosFacHosName') && ('hosFacHosName' === field.name)) {
            lineItemField.default = hospitalName;
            lineItemField.label = hospitalName;
            lineItemField.fieldName = hospitalName;
        } else if ((lineItemField.name === 'hospitalWebsite') && ('hosFacHosWeb' === field.name)) {
            lineItemField.default = hospitalWebsite;
            lineItemField.label = hospitalWebsite;
            lineItemField.fieldName = hospitalWebsite;
        } else if ((lineItemField.name === 'hospitalAddress') && (('hosFacHosAdd' === field.name)
            || ('hospFacHosCntry' === field.name) || ('hosFacHosCity' === field.name) || ('hosFacHosState' === field.name)
            || ('hospitalOtherProv' === field.name) || ('hospitalSaleProv' === field.name))) {
            let state = hospitalCountry === 'US' ? hospitalStateUS : hospitalCountry === 'CA' ? hospitalStateCA : hospitalStateOthers;
            hospitalAddress = hospitalAddress === ' ' ? '' : hospitalAddress;
            hospitalCity = hospitalCity === ' ' ? '' : hospitalCity;
            state = state === ' ' ? '' : state;
            hospitalCountry = hospitalCountry === ' ' ? '' : hospitalCountry;
            lineItemField.default = hospitalAddress + ((hospitalCity && hospitalAddress) || (state && hospitalCity && hospitalAddress) || (hospitalCountry && hospitalCity && hospitalAddress) ? ',' : '') + hospitalCity + ((state && hospitalCity) || (state && hospitalAddress) ? ',' : '') + state + ((hospitalCountry && state) || (hospitalAddress && hospitalCountry) || (hospitalCity && hospitalCountry) ? ',' : '') + hospitalCountry;
            lineItemField.label = hospitalAddress + ((hospitalCity && hospitalAddress) || (state && hospitalCity && hospitalAddress) || (hospitalCountry && hospitalCity && hospitalAddress) ? ',' : '') + hospitalCity + ((state && hospitalCity) || (state && hospitalAddress) ? ',' : '') + state + ((hospitalCountry && state) || (hospitalAddress && hospitalCountry) || (hospitalCity && hospitalCountry) ? ',' : '') + hospitalCountry;
            lineItemField.fieldName = hospitalAddress + ((hospitalCity && hospitalAddress) || (state && hospitalCity && hospitalAddress) || (hospitalCountry && hospitalCity && hospitalAddress) ? ',' : '') + hospitalCity + ((state && hospitalCity) || (state && hospitalAddress) ? ',' : '') + state + ((hospitalCountry && state) || (hospitalAddress && hospitalCountry) || (hospitalCity && hospitalCountry) ? ',' : '') + hospitalCountry;
        }
    });
    if (allSections) {
        if (!allSections[15].groups[1].lineItems[parentIndex]) {
            allSections[15].groups[1].entities.push({ id: new ObjectId().toString(), sections: _.cloneDeep(allSections[15].groups[1].lineSections) });
            allSections[15].groups[1].lineItems.push(_.cloneDeep(allSections[15].groups[1].fields));
        }
        allSections[15].groups[1].lineItems[parentIndex][0].default = hospitalName || '';
        allSections[15].groups[1].lineItems[parentIndex][0].value = hospitalName || '';
        allSections[15].groups[1].lineItems[parentIndex][0].label = hospitalName || '';
        allSections[15].groups[1].entities[parentIndex].sections[0].hospitalName = hospitalName || '';
        allSections[15].groups[1].entities[parentIndex].sections[0].groups[0].fields[2].default = hospitalName || '';
        allSections[15].groups[1].entities[parentIndex].sections[0].groups[0].fields[2].value = hospitalName || ''
        if (fns.saveForm) {
            fns.saveForm(allSections);
        }
    }
}
const removeHospitalSection = (options) => {
    const { parentIndex, allSections, fns } = options;
    if (allSections && parentIndex !== undefined && parentIndex !== null) {
        allSections[15].groups[1].entities.splice(parentIndex, 1);
        allSections[15].groups[1].lineItems.splice(parentIndex, 1);
        if (fns.saveForm) {
            fns.saveForm(allSections);
        }
    }

}
const showNonHospitalNameInSummary = (options) => {
    const { parentGroup, parentIndex, field } = options;
    const name1 = parentGroup.entities[parentIndex].sections;

    let hospitalNonName = '';
    let nonHospitalFaType = '';
    let nonHospitalCity = '';
    let nonHospitalZip = '';
    let nonHospitalStateUS = '';
    let nonHospitalStateOthers = '';
    let nonHospitalStateCA = '';
    let nonHospitalCountry = '';
    name1.forEach((section, index) => {
        if (index === 0) {
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if ((field1.name === 'hosFacNonHosName' && field1.default) || (field.name === 'hosFacNonHosName' && field1.name === 'hosFacNonHosName')) {
                        hospitalNonName = field.name === 'hosFacNonHosName' ? field.default : field1.default
                    } else if ((field1.name === 'hosFacNonHosType' && field1.default) || (field.name === 'hosFacNonHosType' && field1.name === 'hosFacNonHosType')) {
                        nonHospitalFaType = field.name === 'hosFacNonHosType' ? field.default : field1.default
                    } else if ((field1.name === 'hosFacNonHosCity' && field1.default) || (field.name === 'hosFacNonHosCity' && field1.name === 'hosFacNonHosCity')) {
                        nonHospitalCity = field.name === 'hosFacNonHosCity' ? field.default : field1.default
                    } else if ((field1.name === 'hosFacNonHosZip' && field1.default) || (field.name === 'hosFacNonHosZip' && field1.name === 'hosFacNonHosZip')) {
                        nonHospitalZip = field.name === 'hosFacNonHosZip' ? field.default : field1.default
                    } else if ((field1.name === 'hospFacNonHosCntry' && field1.default) || (field.name === 'hospFacNonHosCntry' && field1.name === 'hospFacNonHosCntry')) {
                        nonHospitalCountry = field.name === 'hospFacNonHosCntry' ? field.default : field1.default
                    }

                    if ((field1.name === 'hosFacNonHosState' && field1.default) || (field.name === 'hosFacNonHosState' && field1.name === 'hosFacNonHosState')) {
                        nonHospitalStateUS = field.name === 'hosFacNonHosState' ? field.default : field1.default
                    }
                    if ((field1.name === 'hospitalNonOtherProv' && field1.default) || (field.name === 'hospitalNonOtherProv' && field1.name === 'hospitalNonOtherProv')) {
                        nonHospitalStateOthers = field.name === 'hospitalNonOtherProv' ? field.default : field1.default
                    }
                    if ((field1.name === 'hospitalNonSaleProv' && field1.default) || (field.name === 'hospitalNonSaleProv' && field1.name === 'hospitalNonSaleProv')) {
                        nonHospitalStateCA = field.name === 'hospitalNonSaleProv' ? field.default : field1.default
                    }
                })
            })
        }
    });

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if ((lineItemField.name === 'nonhospitalName') && ('hosFacNonHosName' === field.name)) {
            lineItemField.default = hospitalNonName;
            lineItemField.label = hospitalNonName;
            lineItemField.fieldName = hospitalNonName;
        } else if ((lineItemField.name === 'facilityType') && ('hosFacNonHosType' === field.name)) {
            lineItemField.default = nonHospitalFaType;
            lineItemField.label = nonHospitalFaType;
            lineItemField.fieldName = nonHospitalFaType;
        } else if ((lineItemField.name === 'City') && ('hosFacNonHosCity' === field.name)) {
            lineItemField.default = nonHospitalCity;
            lineItemField.label = nonHospitalCity;
            lineItemField.fieldName = nonHospitalCity;
        } else if ((lineItemField.name === 'Zip') && ('hosFacNonHosZip' === field.name)) {
            lineItemField.default = nonHospitalZip;
            lineItemField.label = nonHospitalZip;
            lineItemField.fieldName = nonHospitalZip;
        } else if ((lineItemField.name === 'State') && (('hosFacNonHosState' === field.name) || (field.name === 'hospitalNonSaleProv') || (field.name === 'hospitalNonOtherProv')
            || ('hospFacNonHosCntry' === field.name))) {
            let nonHospitalStatus = nonHospitalCountry === 'US' ? nonHospitalStateUS : nonHospitalCountry === 'CA' ? nonHospitalStateCA : nonHospitalStateOthers;
            lineItemField.default = nonHospitalStatus;
            lineItemField.label = nonHospitalStatus;
            lineItemField.fieldName = nonHospitalStatus;
        }
    });
}

const showNumberOfHospital = (options) => {
    const { section, group, fns, allSections } = options;
    const totalHospital = group?.entities?.length ? group?.entities?.length.toString() : '1';
    section.groups[0].fields[1].default = totalHospital;
    section.groups[0].fields[1].value = totalHospital;
    if (fns?.saveForm)
        fns.saveForm(section.groups[0].fields[1]);
    if (allSections) {
        allSections[15].groups[1].entities.push({ id: new ObjectId().toString(), sections: _.cloneDeep(options.allSections[15].groups[1].lineSections) });
        allSections[15].groups[1].lineItems.push(_.cloneDeep(options.allSections[15].groups[1].fields));
        const numberHospital = allSections[15].groups[1].entities.length;
        const hospitalName = 'Hospital ' + numberHospital;
        allSections[15].groups[1].lineItems[numberHospital - 1][0].default = hospitalName;
        allSections[15].groups[1].lineItems[numberHospital - 1][0].value = hospitalName;
        allSections[15].groups[1].lineItems[numberHospital - 1][0].label = hospitalName;
        fns.saveForm(allSections)
    }
}

const showHospitalStateProv = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const isUnitedStates = event === EVENT.KEY_UNITED_STATES;
    const isCanada = event === EVENT.KEY_CANADA;
    const isOther = !isUnitedStates && !isCanada;

    name[0].groups[0].fields[6].isVisible = isUnitedStates;
    name[0].groups[0].fields[7].isVisible = isCanada;
    name[0].groups[0].fields[8].isVisible = isOther;
    setFormSections(name);
}
const showNonHospitalStateProv = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    const isUnitedStates = event === EVENT.KEY_UNITED_STATES;
    const isCanada = event === EVENT.KEY_CANADA;
    const isOther = !isUnitedStates && !isCanada;

    name[0].groups[3].fields[1].isVisible = isUnitedStates;
    name[0].groups[3].fields[2].isVisible = isCanada;
    name[0].groups[3].fields[3].isVisible = isOther;
}
const showHosBillPermNone = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    const hosBillPermCredAgcy = name[2].groups[2].fields[1].default;
    const hosBillPermSellDbt = name[2].groups[3].fields[1].default;
    const hosBillPermDefer = name[2].groups[4].fields[1].default;
    const hosBillPermLegal = name[2].groups[5].fields[1].default;
    const hosBillPermOther = name[2].groups[6].fields[1].default;
 
    if (hosBillPermCredAgcy || hosBillPermSellDbt || hosBillPermDefer || hosBillPermLegal || hosBillPermOther) {
        disableCheckbox([name[2].groups[8].fields[1]], true);
        name[2].groups[8].fields[1].default = false;
    } else {
        disableCheckbox([name[2].groups[8].fields[1]], false);
    }
}
const showHosBillPermNoneDisOthr = (event, options) => {
    showHosBillPermNone(event, options);
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    const hosBillPermNone = name[2].groups[8].fields[1].default;
    if (hosBillPermNone) {
        disableCheckbox([name[2].groups[2].fields[1],
            name[2].groups[3].fields[1],
            name[2].groups[4].fields[1],
            name[2].groups[5].fields[1],
            name[2].groups[6].fields[1]], true);
    } else {
        disableCheckbox([name[2].groups[2].fields[1],
            name[2].groups[3].fields[1],
            name[2].groups[4].fields[1],
            name[2].groups[5].fields[1],
            name[2].groups[6].fields[1]], false);
    }
}
const showHosBillTknNone = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    const hosBillTknCredAgcy = name[2].groups[10].fields[1].default;
    const hosBillTknSellDbt = name[2].groups[11].fields[1].default;
    const hosBillTknDefer = name[2].groups[12].fields[1].default;
    const hosBillTknLegal = name[2].groups[13].fields[1].default;
    const hosBillTknOther = name[2].groups[14].fields[1].default;
    if (hosBillTknCredAgcy || hosBillTknSellDbt || hosBillTknDefer || hosBillTknLegal || hosBillTknOther) {
        disableCheckbox([name[2].groups[16].fields[1]], true);
        name[2].groups[16].fields[1].default = false;
    } else {
        disableCheckbox([name[2].groups[16].fields[1]], false);
    }
}
const showHosBillTknNoneDisOthr = (event, options) => {
    showHosBillTknNone(event, options);
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    const hosBillTknNone = name[2].groups[16].fields[1].default;

    if (hosBillTknNone) {
        disableCheckbox([name[2].groups[10].fields[1],
            name[2].groups[11].fields[1],
            name[2].groups[12].fields[1],
            name[2].groups[13].fields[1],
            name[2].groups[14].fields[1]], true);
    } else {
        disableCheckbox([name[2].groups[10].fields[1],
            name[2].groups[11].fields[1],
            name[2].groups[12].fields[1],
            name[2].groups[13].fields[1],
            name[2].groups[14].fields[1]], false);
    }
}
const showHosBillInitNone = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    const hosBillInitWrtnNote = name[2].groups[18].fields[1].default;
    const hosBillInitOralNote = name[2].groups[19].fields[1].default;
    const hosBillInitAppsProc = name[2].groups[20].fields[1].default;
    const hosBillInitPrsmptv = name[2].groups[21].fields[1].default;
    const hosBillInitOth = name[2].groups[22].fields[1].default;

    if (hosBillInitWrtnNote || hosBillInitOralNote || hosBillInitAppsProc || hosBillInitPrsmptv || hosBillInitOth) {
        disableCheckbox([name[2].groups[24].fields[1]], true);
        name[2].groups[24].fields[1].default = false;
    } else {
        disableCheckbox([name[2].groups[24].fields[1]], false);
    }
}
const showHosBillInitNoneDisOthr = (event, options) => {
    showHosBillInitNone(event, options);
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    const hosBillInitNone = name[2].groups[24].fields[1].default;

    if (hosBillInitNone) {
        disableCheckbox([name[2].groups[18].fields[1],
            name[2].groups[19].fields[1],
            name[2].groups[20].fields[1],
            name[2].groups[21].fields[1],
            name[2].groups[22].fields[1]
        ], true);
        
    } else {
        disableCheckbox([name[2].groups[18].fields[1],
            name[2].groups[19].fields[1],
            name[2].groups[20].fields[1],
            name[2].groups[21].fields[1],
            name[2].groups[22].fields[1]
        ], false);
    }
}

const disableCheckbox = (checkboxes, isDisable) => {
    checkboxes.forEach((checkbox) => {
        checkbox.disabled = isDisable;
        checkbox.isDisable = isDisable;
    })
}

const showhosFaIncmLmtFreeOth = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
        name[2].groups[4].isVisible = event === "Other";
}
const showHosFaIncmLmtDiscOth = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
        name[2].groups[7].isVisible = event === "Other";
}

const showhosChnaYrAdopt = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[0].groups[28].isVisible = event === EVENT.KEY_YES;
    name[0].groups[24].isVisible = event === EVENT.KEY_YES;
    name[0].groups[25].isVisible = event === EVENT.KEY_YES;
    if(name[0].groups[25].isVisible && name[0].groups[25].fields[1].default === EVENT.KEY_YES){
        name[0].groups[26].isVisible = true;
    }else{
        name[0].groups[26].isVisible = false;
    }
    if(name[0].groups[25].isVisible && name[0].groups[25].fields[1].default === EVENT.KEY_NO){
        name[0].groups[27].isVisible = true;
    }else{
        name[0].groups[27].isVisible = false;
    }
}

const showhosChnaImpStratAvailWebAdd = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[0].groups[27].isVisible = event === EVENT.KEY_NO;
    name[0].groups[26].isVisible = event === EVENT.KEY_YES;
}

const showhosChnaOtherDesc = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[0].groups[40].isVisible = event;
}

const showNonHospitalGroup = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    name[5].groups[5].isVisible = event === EVENT.KEY_YES;
    name[5].groups[4].isVisible = event === EVENT.KEY_YES;
}
const setHospitalName = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    name.forEach((section, index) => {
        if (index === 0) {
            section.groups.forEach(group => {
                group.fields.forEach(field => {
                    if (field.name === 'hosFacHosName') {
                        field.default = event;

                        if (group.title) {
                            group.individualName = event && event !== ' ' ? event : '';
                        }
                    }
                })
            })
        }
    });

    setFormSections(name);

}
const showhosFacHosType8Desc = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    name[0].groups[11].isVisible = event;
}

const showHosCbCharOffTaxAmt = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    const hosGenCbPrepRpt = name[0].groups[2].fields[1].default;
    const showHosCbCharOffTaxAmt = name[7].groups[1].fields[1].default;

    if (showHosCbCharOffTaxAmt === EVENT.KEY_YES && hosGenCbPrepRpt === EVENT.KEY_NO) {
        name[7].groups[2].isVisible = true;
    } else {
        name[7].groups[2].isVisible = false;
    }
}
const setFapDetails = (options) => {
    const { parentGroup, parentIndex, section } = options;;
    let hosFapWebAddVal = [];
    section.groups.forEach((group1) => {
        group1.fields.forEach((field1) => {
            if (field1.default) {
                if (field1.name === 'hosFapWebAdd' && field1.default.trim().length > 0) {
                    hosFapWebAddVal.push(`FAP Website:${field1.default}`);
                } else if (field1.name === 'hosFapAppWebAdd' && field1.default.trim().length > 0) {
                    hosFapWebAddVal.push(`FAP Plain Language Summary Website:${field1.default}`);
                }
                else if (field1.name === 'hosFapPlainLangWebAdd' && field1.default.trim().length > 0) {
                    hosFapWebAddVal.push(`Financial Assistance Application Website:${field1.default}`);
                }
            }
        });
    });
    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if (lineItemField.name === 'FAPdetails') {
            lineItemField.default = hosFapWebAddVal.join('\n');
        }
    });
}

const showHosCbCharCarePoolRevRcv = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    const hosGenCbPrepRpt = name[0].groups[2].fields[1].default;
    const hosCbCharCarePoolRev = name[7].groups[3].fields[1].default;


    if (hosCbCharCarePoolRev === EVENT.KEY_YES && hosGenCbPrepRpt === EVENT.KEY_NO) {
        name[7].groups[4].isVisible = true;
    } else {
        name[7].groups[4].isVisible = false;
    }
}

const showHosCbCharCostsCalcSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    let hosCbCharOffTaxAmt = 0;
    if (name[7].groups[1].fields[1].default === EVENT.KEY_YES && name[7].groups[2].fields[1].default === undefined) {
        hosCbCharOffTaxAmt = 0
    } else if (name[7].groups[1].fields[1].default === EVENT.KEY_NO) {
        hosCbCharOffTaxAmt = 0
    } else if (name[7].groups[1].fields[1].default === undefined) {
        hosCbCharOffTaxAmt = 0
    } else {
        hosCbCharOffTaxAmt = parseFloat(name[7].groups[2].fields[1].default);
    }

    const hosCbCharGrossWO = name[7].groups[0].fields[1].default !== undefined ? parseFloat(name[7].groups[0].fields[1].default) : 0;

    const hosCbCostChrRatio = (name[6].groups[1].fields[1].default) / 100

    const hospCbCharOffSetSubTtlCalculated = hosCbCharGrossWO * hosCbCostChrRatio + hosCbCharOffTaxAmt

    const dollerUSlocale = Intl.NumberFormat('en-US');

    const hospCbCharOffSetSubTtl = `Total Benefit Expense \n $${dollerUSlocale.format(hospCbCharOffSetSubTtlCalculated.toFixed())}`;

    name[7].groups[5].fields[1].default = hospCbCharOffSetSubTtl
}

const showHospCbCharOffSetSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    const hosCbCharCarePoolRevRcv = parseFloat(name[7].groups[4].fields[1].default);

    const dollerUSlocale = Intl.NumberFormat('en-US');

    const hosCbCharCostsCalcSubTtl = `Direct Offsetting Revenue \n $${dollerUSlocale.format(hosCbCharCarePoolRevRcv.toFixed())}`;

    if (name[7].groups[3].fields[1].default === EVENT.KEY_YES && name[7].groups[4].fields[1].default === undefined) {
        name[7].groups[5].fields[2].default = `Direct Offsetting Revenue \n $0`
    } else if (name[7].groups[3].fields[1].default === EVENT.KEY_NO) {
        name[7].groups[5].fields[2].default = `Direct Offsetting Revenue \n $0`
    } else {
        name[7].groups[5].fields[2].default = hosCbCharCostsCalcSubTtl
    }

}

const showCommunityBenefitReports = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    const hosGenCbPrepRpt = name[0].groups[2].fields[1].default;

    const commBenReportsSection = name[6]

    if (hosGenCbPrepRpt === EVENT.KEY_NO || hosGenCbPrepRpt === EVENT.KEY_YES) {
        commBenReportsSection.isVisible = true
    } else {
        commBenReportsSection.isVisible = false
    }

    if (hosGenCbPrepRpt === EVENT.KEY_YES) {
        //Community Benefit Reports
        name[6].groups[1].isVisible = false
        //Charity Care
        name[7].groups[0].isVisible = false
        name[7].groups[1].isVisible = false
        name[7].groups[3].isVisible = false
        name[7].groups[5].isVisible = false
        name[7].groups[6].isVisible = true
        name[7].groups[7].isVisible = true

        //Medicaid
        name[8].groups[0].isVisible = false
        name[8].groups[1].isVisible = false
        name[8].groups[2].isVisible = false
        name[8].groups[3].isVisible = false
        name[8].groups[4].isVisible = false
        name[8].groups[5].isVisible = false
        name[8].groups[6].isVisible = false
        name[8].groups[7].isVisible = false
        name[8].groups[8].isVisible = false
        name[8].groups[9].isVisible = false
        name[8].groups[10].isVisible = true
        name[8].groups[11].isVisible = true

        // Other Means - Tested Gov Programs
        name[9].groups[0].isVisible = false
        name[9].groups[1].isVisible = false
        name[9].groups[2].isVisible = false
        name[9].groups[3].isVisible = false
        name[9].groups[4].isVisible = false
        name[9].groups[5].isVisible = false
        name[9].groups[6].isVisible = false
        name[9].groups[7].isVisible = false
        name[9].groups[8].isVisible = false
        name[9].groups[9].isVisible = false
        name[9].groups[10].isVisible = true
        name[9].groups[11].isVisible = true

        // Comm Health Imprv/Benefit Ops
        name[10].groups[0].isVisible = false
        name[10].groups[1].isVisible = false
        name[10].groups[2].isVisible = false
        name[10].groups[3].isVisible = false
        name[10].groups[4].isVisible = false
        name[10].groups[5].isVisible = false
        name[10].groups[6].isVisible = false
        name[10].groups[7].isVisible = false
        name[10].groups[8].isVisible = false
        name[10].groups[9].isVisible = false
        name[10].groups[10].isVisible = false
        name[10].groups[11].isVisible = false
        name[10].groups[12].isVisible = false
        name[10].groups[13].isVisible = true
        name[10].groups[14].isVisible = true

        //Health Professions Ed
        name[11].groups[0].isVisible = false
        name[11].groups[1].isVisible = false
        name[11].groups[2].isVisible = false
        name[11].groups[3].isVisible = false
        name[11].groups[4].isVisible = false
        name[11].groups[5].isVisible = false
        name[11].groups[6].isVisible = false
        name[11].groups[7].isVisible = true
        name[11].groups[8].isVisible = true

        //Subsidized Hlth Svcs
        name[12].groups[0].isVisible = false
        name[12].groups[1].isVisible = false
        name[12].groups[2].isVisible = false
        name[12].groups[3].isVisible = false
        name[12].groups[4].isVisible = true
        name[12].groups[5].isVisible = true

        // Medical Research
        name[13].groups[0].isVisible = false
        name[13].groups[1].isVisible = false
        name[13].groups[2].isVisible = false
        name[13].groups[3].isVisible = false
        name[13].groups[4].isVisible = true
        name[13].groups[5].isVisible = true

        //Cash/In-Kind Contrib
        name[14].groups[0].isVisible = false
        name[14].groups[1].isVisible = false
        name[14].groups[2].isVisible = false
        name[14].groups[3].isVisible = false
        name[14].groups[4].isVisible = true
        name[14].groups[5].isVisible = true

    } else {
        //Community Benefit Reports
        name[6].groups[1].isVisible = true

        //Charity Care
        name[7].groups[0].isVisible = true
        name[7].groups[1].isVisible = true
        name[7].groups[3].isVisible = true
        name[7].groups[5].isVisible = true
        name[7].groups[6].isVisible = false
        name[7].groups[7].isVisible = false

        //Medicaid
        name[8].groups[0].isVisible = true
        name[8].groups[1].isVisible = true
        name[8].groups[3].isVisible = true
        name[8].groups[5].isVisible = true
        name[8].groups[7].isVisible = true
        name[8].groups[9].isVisible = true
        name[8].groups[10].isVisible = false
        name[8].groups[11].isVisible = false

        // Other Means - Tested Gov Programs
        name[9].groups[0].isVisible = true
        name[9].groups[1].isVisible = true
        name[9].groups[3].isVisible = true
        name[9].groups[5].isVisible = true
        name[9].groups[7].isVisible = true
        name[9].groups[9].isVisible = true
        name[9].groups[10].isVisible = false
        name[9].groups[11].isVisible = false

        // Comm Health Imprv/Benefit Ops
        name[10].groups[0].isVisible = true
        name[10].groups[3].isVisible = true
        name[10].groups[6].isVisible = true
        name[10].groups[9].isVisible = true
        name[10].groups[12].isVisible = true
        name[10].groups[13].isVisible = false
        name[10].groups[14].isVisible = false

        //Health Professions Ed
        name[11].groups[0].isVisible = true
        name[11].groups[6].isVisible = true
        name[11].groups[7].isVisible = false
        name[11].groups[8].isVisible = false

        //Subsidized Hlth Svcs
        name[12].groups[0].isVisible = true
        name[12].groups[3].isVisible = true
        name[12].groups[4].isVisible = false
        name[12].groups[5].isVisible = false

        // Medical Research
        name[13].groups[0].isVisible = true
        name[13].groups[3].isVisible = true
        name[13].groups[4].isVisible = false
        name[13].groups[5].isVisible = false

        //Cash/In-Kind Contrib
        name[14].groups[0].isVisible = true
        name[14].groups[3].isVisible = true
        name[14].groups[4].isVisible = false
        name[14].groups[5].isVisible = false
    }
}
const showhosFacAddInfo = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_YES) {
        name[16].groups[1].isVisible = true;
        name[16].groups[0].hasDivider = false;

    } else {
        name[16].groups[1].isVisible = false;
        name[16].groups[0].hasDivider = true;
    }
};

const showhosBillInitOthDescLabel = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    name[2].groups[23].isVisible = event;
}

const showhosBillTknOtherDescLabel = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    name[2].groups[15].isVisible = event;
}

const showhosBillPermOtherDescLabel = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    name[2].groups[7].isVisible = event;
}

const showhosChrgMoreInsCovExp = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    name[4].groups[8].isVisible = event;
}

const showhosChrgEqlGrossChrgExp = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    name[4].groups[10].isVisible = event;
}

const showhosCbMaProvTaxAmt = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[8].groups[1].fields[1].default === EVENT.KEY_YES && name[8].groups[1].isVisible) {
        name[8].groups[2].isVisible = true
    } else {
        name[8].groups[2].isVisible = false
    }
}

const showhosCbMaCostsCalcSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    const hosCbCostChrRatio = name[6].groups[1].fields[1].default / 100;
    const hosCbMaGrsChrgMa = name[8].groups[0].fields[1].default !== undefined ? parseInt(name[8].groups[0].fields[1].default) : 0;
    const hosCbMaProvTax = name[8].groups[1].fields[1].default;
    const hosCbMaProvTaxAmt = name[8].groups[2].fields[1].default && hosCbMaProvTax === EVENT.KEY_YES ? parseInt(name[8].groups[2].fields[1].default) : 0
 
    const hosCbMaCostsCalcSubTtl = (hosCbCostChrRatio * hosCbMaGrsChrgMa) + hosCbMaProvTaxAmt

    name[8].groups[9].fields[1].default = `Total Benefit Expense \n ${amountCurrencyFormatter(hosCbMaCostsCalcSubTtl)}`;
}

const showhosCbMaOffSetSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    const hosCbMaNetPatRev = name[8].groups[3].fields[1].default;
    const hosCbMaCarePoolPay = name[8].groups[5].fields[1].default;
    const hosCbMaOthRev = name[8].groups[7].fields[1].default;

    const hosCbMaNetPatRevAmt = hosCbMaNetPatRev === EVENT.KEY_YES && name[8].groups[4].fields[1].default !== undefined ? name[8].groups[4].fields[1].default : 0;
    const hosCbMaCarePoolPayAmt = hosCbMaCarePoolPay === EVENT.KEY_YES && name[8].groups[6].fields[1].default !== undefined ? name[8].groups[6].fields[1].default : 0;
    const hosCbMaOthRevAmt = hosCbMaOthRev === EVENT.KEY_YES && name[8].groups[8].fields[1].default !== undefined ? name[8].groups[8].fields[1].default : 0;

    const totalAmount = +hosCbMaNetPatRevAmt + +hosCbMaCarePoolPayAmt + +hosCbMaOthRevAmt
    if (totalAmount) {
        name[8].groups[9].fields[2].default = `Direct Offsetting Revenue \n ${amountCurrencyFormatter(totalAmount)}`;
    } else {
        name[8].groups[9].fields[2].default = 'Direct Offsetting Revenue \n $0';
    }
}

const showhosCbMaNetPatRevAmtt = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[8].groups[3].fields[1].default === EVENT.KEY_YES && name[8].groups[3].isVisible) {
        name[8].groups[4].isVisible = true
    } else {
        name[8].groups[4].isVisible = false
    }
}


const showhosCbMaCarePoolPayAmt = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[8].groups[5].fields[1].default === EVENT.KEY_YES && name[8].groups[5].isVisible) {
        name[8].groups[6].isVisible = true
    } else {
        name[8].groups[6].isVisible = false
    }
}

const showhosCbMaOthRevAmt = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[8].groups[7].fields[1].default === EVENT.KEY_YES && name[8].groups[7].isVisible) {
        name[8].groups[8].isVisible = true
    } else {
        name[8].groups[8].isVisible = false
    }
}

const showHosFacAddDocsAttach = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_YES) {
        name[16].groups[3].isVisible = true;

    } else {
        name[16].groups[3].isVisible = false;
    }
};
const showhosEmgcLmtdEligDesc = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[3].groups[8].isVisible = event === true;
};
const showHosEmgcOthDescLabel = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[3].groups[10].isVisible = event === true;
};
const showHosFapOthIncmLvlDesc = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[1].groups[3].isVisible = event === true;
};
const showHosFapOthDesc = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[1].groups[10].isVisible = event === true;
};
const showHosFapFinAsstMthLabel11 = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[1].groups[13].isVisible = name[1].groups[14].isVisible = name[1].groups[15].isVisible =
        name[1].groups[16].isVisible = name[1].groups[17].isVisible = name[1].groups[18].isVisible = event === true;
    name[1].groups[19].isVisible = name[1].groups[18].isVisible && name[1].groups[18].fields[1].default === true;
};
const showHosFapOtherAssistDesc = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[1].groups[19].isVisible = event === true;
};
const showHosFapWrtMeasPub1 = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[1].groups[21].isVisible = name[1].groups[22].isVisible = name[1].groups[24].isVisible = name[1].groups[26].isVisible = name[1].groups[28].isVisible =
        name[1].groups[29].isVisible = name[1].groups[30].isVisible = name[1].groups[31].isVisible = name[1].groups[32].isVisible =
        name[1].groups[33].isVisible = name[1].groups[34].isVisible = name[1].groups[35].isVisible = name[1].groups[36].isVisible =
        name[1].groups[37].isVisible = event === true;
    name[1].groups[23].isVisible = name[1].groups[22].isVisible && name[1].groups[22].fields[1].default === true;
    name[1].groups[25].isVisible = name[1].groups[24].isVisible && name[1].groups[24].fields[1].default === true;
    name[1].groups[27].isVisible = name[1].groups[26].isVisible && name[1].groups[26].fields[1].default === true;
    name[1].groups[38].isVisible = name[1].groups[37].isVisible && name[1].groups[37].fields[1].default === true;

};

const showHosFapAppWebAdd = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[1].groups[25].isVisible = event === true;
};
const showHosFapPlainLangWebAdd = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[1].groups[27].isVisible = event === true;
};

const showHosFapWebAdd = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[1].groups[23].isVisible = event === true;
};
const showHosFapOthPubDesc = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[1].groups[38].isVisible = event === true;
};
const showhosEmgcWtnPolLabel4 = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[3].groups[4].isVisible = name[3].groups[5].isVisible = name[3].groups[6].isVisible = name[3].groups[7].isVisible = name[3].groups[9].isVisible = !(event === EVENT.KEY_YES);
    name[3].groups[8].isVisible = name[3].groups[7].isVisible && name[3].groups[7].fields[1].default === true;
    name[3].groups[10].isVisible = name[3].groups[9].isVisible && name[3].groups[9].fields[1].default === true;
};

const showHosCbOthMnsTaxAmt = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[9].groups[1].fields[1].default === EVENT.KEY_YES && name[9].groups[1].isVisible) {
        name[9].groups[2].isVisible = true;

    } else {
        name[9].groups[2].isVisible = false;
    }
}

const showHosCbOthMnsNetPatRevAmt = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[9].groups[3].fields[1].default === EVENT.KEY_YES && name[9].groups[3].isVisible) {
        name[9].groups[4].isVisible = true;

    } else {
        name[9].groups[4].isVisible = false;
    }
}

const showHosCbOthMnsCarePoolPayAmt = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[9].groups[5].fields[1].default === EVENT.KEY_YES && name[9].groups[5].isVisible) {
        name[9].groups[6].isVisible = true;

    } else {
        name[9].groups[6].isVisible = false;
    }
}

const showHosCbOthMnsOthRevAmt = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[9].groups[7].fields[1].default === EVENT.KEY_YES && name[9].groups[7].isVisible) {
        name[9].groups[8].isVisible = true;

    } else {
        name[9].groups[8].isVisible = false;
    }
}

const showHosCbOthMnsOffSetSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    let hosCbOthMnsGrsChrg = 0
    let hosCbOthMnsTaxAmt = 0

    if (name[9].groups[0].fields[1].default === undefined) {
        hosCbOthMnsGrsChrg = 0
    } else {
        hosCbOthMnsGrsChrg = parseFloat(name[9].groups[0].fields[1].default);
    }

    if (name[9].groups[1].fields[1].default === EVENT.KEY_NO) {
        hosCbOthMnsTaxAmt = 0
    } else if (name[9].groups[1].fields[1].default === EVENT.KEY_YES && name[9].groups[2].fields[1].default === undefined) {
        hosCbOthMnsTaxAmt = 0
    } else {
        hosCbOthMnsTaxAmt = parseFloat(name[9].groups[2].fields[1].default);
    }

    let hosCbOthMnsOffSetSubTtl = hosCbOthMnsGrsChrg + hosCbOthMnsTaxAmt;

    const hosCbOthMnsOffSetSubTtlLabel = `Total Benefit Expense\n ${amountCurrencyFormatter(hosCbOthMnsOffSetSubTtl)}`;

    name[9].groups[9].fields[1].default = hosCbOthMnsOffSetSubTtlLabel
}

const calculateHosCbOthMnsCostsCalcSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    let hosCbOthMnsNetPatRevAmt = 0
    let hosCbOthMnsCarePoolPayAmt = 0
    let hosCbOthMnsOthRevAmt = 0

    if (name[9].groups[3].fields[1].default === EVENT.KEY_YES && name[9].groups[4].fields[1].default === undefined) {
        hosCbOthMnsNetPatRevAmt = 0
    } else if (name[9].groups[3].fields[1].default === undefined && name[9].groups[4].fields[1].default === undefined) {
        hosCbOthMnsNetPatRevAmt = 0
    } else if (name[9].groups[3].fields[1].default === EVENT.KEY_NO) {
        hosCbOthMnsNetPatRevAmt = 0
    } else {
        hosCbOthMnsNetPatRevAmt = parseFloat(name[9].groups[4].fields[1].default);
    }

    if (name[9].groups[5].fields[1].default === EVENT.KEY_YES && name[9].groups[6].fields[1].default === undefined) {
        hosCbOthMnsCarePoolPayAmt = 0
    } else if (name[9].groups[5].fields[1].default === undefined && name[9].groups[6].fields[1].default === undefined) {
        hosCbOthMnsCarePoolPayAmt = 0
    } else if (name[9].groups[5].fields[1].default === EVENT.KEY_NO) {
        hosCbOthMnsCarePoolPayAmt = 0
    } else {
        hosCbOthMnsCarePoolPayAmt = parseFloat(name[9].groups[6].fields[1].default);
    }

    if (name[9].groups[7].fields[1].default === EVENT.KEY_YES && name[9].groups[8].fields[1].default === undefined) {
        hosCbOthMnsOthRevAmt = 0
    } else if (name[9].groups[7].fields[1].default === undefined && name[9].groups[8].fields[1].default === undefined) {
        hosCbOthMnsOthRevAmt = 0
    } else if (name[9].groups[7].fields[1].default === EVENT.KEY_NO) {
        hosCbOthMnsOthRevAmt = 0
    } else {
        hosCbOthMnsOthRevAmt = parseFloat(name[9].groups[8].fields[1].default);
    }

    let hosCbOthMnsCostsCalcSubTtl = hosCbOthMnsNetPatRevAmt + hosCbOthMnsCarePoolPayAmt + hosCbOthMnsOthRevAmt;


    const hosCbOthMnsOffSetSubTtl = `Direct Offsetting Revenue \n ${amountCurrencyFormatter(hosCbOthMnsCostsCalcSubTtl)}`;

    name[9].groups[9].fields[2].default = hosCbOthMnsOffSetSubTtl

}

const showHosCbProExpenses = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[11].groups[0].fields[1].default === EVENT.KEY_YES && name[11].groups[0].isVisible) {
        name[11].groups[1].isVisible = true;
        name[11].groups[2].isVisible = true;
        name[11].groups[3].isVisible = true;
        name[11].groups[4].isVisible = true;
        name[11].groups[5].isVisible = true;

    } else {
        name[11].groups[1].isVisible = false;
        name[11].groups[2].isVisible = false;
        name[11].groups[3].isVisible = false;
        name[11].groups[4].isVisible = false;
        name[11].groups[5].isVisible = false;

    }
}

const showHosCbProEdOffSetSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    let hosCbProEdOffSetSubTtl = 0

    if (name[11].groups[0].fields[1].default === EVENT.KEY_NO) {
        hosCbProEdOffSetSubTtl = 0
    } else if (name[11].groups[0].fields[1].default === undefined && name[11].groups[1].fields[1].default === undefined) {
        hosCbProEdOffSetSubTtl = 0
    } else if (name[11].groups[0].fields[1].default === EVENT.KEY_YES && name[11].groups[1].fields[1].default === undefined) {
        hosCbProEdOffSetSubTtl = 0
    } else {
        hosCbProEdOffSetSubTtl = parseFloat(name[11].groups[1].fields[1].default)
    }


    const hosCbProEdOffSetSubTtlLabel = `Total Benefit Expense\n ${amountCurrencyFormatter(hosCbProEdOffSetSubTtl)}`;

    name[11].groups[6].fields[1].default = hosCbProEdOffSetSubTtlLabel

}

const calculateHosCbProEdCostsCalcSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    let hosCbProEdMcGradExp = 0
    let hosCbProEdMaGradExp = 0
    let hosCbProEdContExp = 0
    let hosCbProEdRevOffset = 0

    if (name[11].groups[0].fields[1].default === EVENT.KEY_YES && name[11].groups[2].fields[1].default === undefined) {
        hosCbProEdMcGradExp = 0
    } else if (name[11].groups[0].fields[1].default === undefined && name[11].groups[2].fields[1].default === undefined) {
        hosCbProEdMcGradExp = 0
    } else if (name[11].groups[0].fields[1].default === EVENT.KEY_NO) {
        hosCbProEdMcGradExp = 0
    } else {
        hosCbProEdMcGradExp = parseFloat(name[11].groups[2].fields[1].default)
    }

    if (name[11].groups[0].fields[1].default === EVENT.KEY_YES && name[11].groups[3].fields[1].default === undefined) {
        hosCbProEdMaGradExp = 0
    } else if (name[11].groups[0].fields[1].default === undefined && name[11].groups[3].fields[1].default === undefined) {
        hosCbProEdMaGradExp = 0
    } else if (name[11].groups[0].fields[1].default === EVENT.KEY_NO) {
        hosCbProEdMaGradExp = 0
    } else {
        hosCbProEdMaGradExp = parseFloat(name[11].groups[3].fields[1].default)
    }

    if (name[11].groups[0].fields[1].default === EVENT.KEY_YES && name[11].groups[4].fields[1].default === undefined) {
        hosCbProEdContExp = 0
    } else if (name[11].groups[0].fields[1].default === undefined && name[11].groups[4].fields[1].default === undefined) {
        hosCbProEdContExp = 0
    } else if (name[11].groups[0].fields[1].default === EVENT.KEY_NO) {
        hosCbProEdContExp = 0
    } else {
        hosCbProEdContExp = parseFloat(name[11].groups[4].fields[1].default)
    }

    if (name[11].groups[0].fields[1].default === EVENT.KEY_YES && name[11].groups[5].fields[1].default === undefined) {
        hosCbProEdRevOffset = 0
    } else if (name[11].groups[0].fields[1].default === undefined && name[11].groups[5].fields[1].default === undefined) {
        hosCbProEdRevOffset = 0
    } else if (name[11].groups[0].fields[1].default === EVENT.KEY_NO) {
        hosCbProEdRevOffset = 0
    } else {
        hosCbProEdRevOffset = parseFloat(name[11].groups[5].fields[1].default)
    }

    let hosCbProEdCostsCalcSubTtl = hosCbProEdMcGradExp + hosCbProEdMaGradExp + hosCbProEdContExp + hosCbProEdRevOffset;


    const hosCbProEdCostsCalcSubTtlLabel = `Direct Offsetting Revenue \n ${amountCurrencyFormatter(hosCbProEdCostsCalcSubTtl)}`;

    name[11].groups[6].fields[2].default = hosCbProEdCostsCalcSubTtlLabel

}

const showHosCbComHlthNdsAssessExp = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[10].groups[3].fields[1].default === EVENT.KEY_YES && name[10].groups[3].isVisible) {
        name[10].groups[4].isVisible = true
        name[10].groups[5].isVisible = true
    } else {
        name[10].groups[4].isVisible = false;
        name[10].groups[5].isVisible = false
    }

    totalBenefitsCal(name);
};
const showHosCbComHlthSubPrgrmExp = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[10].groups[0].fields[1].default === EVENT.KEY_YES && name[10].groups[0].isVisible) {
        name[10].groups[1].isVisible = true
        name[10].groups[2].isVisible = true
    } else {
        name[10].groups[1].isVisible = false;
        name[10].groups[2].isVisible = false
    }

    totalBenefitsCal(name);
};
const showHosCbComHlthComBnftExp = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[10].groups[6].fields[1].default === EVENT.KEY_YES && name[10].groups[6].isVisible) {
        name[10].groups[7].isVisible = true
        name[10].groups[8].isVisible = true
    } else {
        name[10].groups[7].isVisible = false;
        name[10].groups[8].isVisible = false
    }

    totalBenefitsCal(name);
};
const showHosCbComHlthFundExp = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[10].groups[9].fields[1].default === EVENT.KEY_YES && name[10].groups[9].isVisible) {
        name[10].groups[10].isVisible = true
        name[10].groups[11].isVisible = true
    } else {
        name[10].groups[10].isVisible = false;
        name[10].groups[11].isVisible = false
    }

    totalBenefitsCal(name);
};
const totalBenefitExpenseCal = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    totalBenefitsCal(name);
};
const totalBenefitsCal = (name) => {
    const hosCbComHlthSubPrgrm = name[10].groups[0].fields[1].default;
    const hosCbComHlthNdsAssess = name[10].groups[3].fields[1].default;
    const hosCbComHlthComBnft = name[10].groups[6].fields[1].default;
    const hosCbComHlthFund = name[10].groups[9].fields[1].default;

    const hosCbComHlthSubPrgrmExp = hosCbComHlthSubPrgrm === EVENT.KEY_YES && name[10].groups[1].fields[1].default ? parseInt(name[10].groups[1].fields[1].default) : 0;
    const hosCbComHlthSubPrgrmRev = hosCbComHlthSubPrgrm === EVENT.KEY_YES && name[10].groups[2].fields[1].default ? parseInt(name[10].groups[2].fields[1].default) : 0;
    const hosCbComHlthNdsAssessExp = hosCbComHlthNdsAssess === EVENT.KEY_YES && name[10].groups[4].fields[1].default ? parseInt(name[10].groups[4].fields[1].default) : 0;
    const hosCbComHlthNdsAssessRev = hosCbComHlthNdsAssess === EVENT.KEY_YES && name[10].groups[5].fields[1].default ? parseInt(name[10].groups[5].fields[1].default) : 0;
    const hosCbComHlthComBnftExp = hosCbComHlthComBnft === EVENT.KEY_YES && name[10].groups[7].fields[1].default ? parseInt(name[10].groups[7].fields[1].default) : 0;
    const hosCbComHlthComBnftAmt = hosCbComHlthComBnft === EVENT.KEY_YES && name[10].groups[8].fields[1].default ? parseInt(name[10].groups[8].fields[1].default) : 0;
    const hosCbComHlthFundExp = hosCbComHlthFund === EVENT.KEY_YES && name[10].groups[10].fields[1].default ? parseInt(name[10].groups[10].fields[1].default) : 0;
    const hosCbComHlthFundAmt = hosCbComHlthFund === EVENT.KEY_YES && name[10].groups[11].fields[1].default ? parseInt(name[10].groups[11].fields[1].default) : 0;
    const totalBenefitAmount = hosCbComHlthSubPrgrmExp + hosCbComHlthNdsAssessExp + hosCbComHlthComBnftExp + hosCbComHlthFundExp


    if (totalBenefitAmount) {
        name[10].groups[12].fields[1].default = `Total Benefit Expense \n ${amountCurrencyFormatter(totalBenefitAmount)}`;
    } else {
        name[10].groups[12].fields[1].default = 'Total Benefit Expense \n $0';
    }
    const totalDirectOffsetAmount = hosCbComHlthSubPrgrmRev + hosCbComHlthNdsAssessRev + hosCbComHlthComBnftAmt + hosCbComHlthFundAmt
    if (totalDirectOffsetAmount) {
        name[10].groups[12].fields[2].default = `Direct Offsetting Revenue \n ${amountCurrencyFormatter(totalDirectOffsetAmount)}`;
    } else {
        name[10].groups[12].fields[2].default = 'Direct Offsetting Revenue \n $0';
    }
};
const showhosCbSubOffSetSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    subhlthsvcsCal1(name);
};

const subhlthsvcsCal1 = (name) => {
    const hosCbCharOffTaxAmt = parseFloat(name[12].groups[1].fields[1].default);

    const hosCbSubIncurExp = `Total Benefit Expense \n ${amountCurrencyFormatter(hosCbCharOffTaxAmt)}`;
    if (name[12].groups[0].fields[1].default === EVENT.KEY_YES && name[12].groups[1].fields[1].default === undefined) {
        name[12].groups[3].fields[1].default = `Total Benefit Expense \n $0`
    } else {
        name[12].groups[3].fields[1].default = hosCbSubIncurExp
    }
}
const showhosCbSubCostsCalcSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    subhlthsvcsCal2(name);
};
const subhlthsvcsCal2 = (name) => {
    const hosCbCharOffTaxAmt = parseFloat(name[12].groups[2].fields[1].default);

    const hosCbSubIncurExp = `Direct Offsetting Revenue \n ${amountCurrencyFormatter(hosCbCharOffTaxAmt)}`;
    if (name[12].groups[0].fields[1].default === EVENT.KEY_YES && name[12].groups[1].fields[1].default === undefined) {
        name[12].groups[3].fields[2].default = `Direct Offsetting Revenue \n $0`
    } else {
        name[12].groups[3].fields[2].default = hosCbSubIncurExp
    }
}
const showhosCbSubLoss = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (name[12].groups[0].fields[1].default === EVENT.KEY_YES && name[12].groups[0].isVisible) {
        name[12].groups[1].isVisible = true;
        name[12].groups[2].isVisible = true;
        subhlthsvcsCal1(name);
        subhlthsvcsCal2(name);
    } else {
        name[12].groups[1].isVisible = false;
        name[12].groups[2].isVisible = false;
        name[12].groups[3].fields[1].default = `Total Benefit Expense \n $0`;
        name[12].groups[3].fields[2].default = `Direct Offsetting Revenue \n $0`;
    }

    // if (name[12].groups[0].isVisible && name[12].groups[0].fields[1].default !== undefined) {
    //     name[12].groups[3].isVisible = true;
    // } else {
    //     name[12].groups[3].isVisible = false;
    // }
};

const showhosCbRsrchFund = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (name[13].groups[0].fields[1].default === EVENT.KEY_YES && name[13].groups[0].isVisible) {
        name[13].groups[1].isVisible = true;
        name[13].groups[2].isVisible = true;
        medicalcostCal1(name);
        medicalcostCal2(name);
    } else {
        name[13].groups[1].isVisible = false;
        name[13].groups[2].isVisible = false;
        name[13].groups[3].fields[1].default = `Total Benefit Expense \n $0`;
        name[13].groups[3].fields[2].default = `Direct Offsetting Revenue \n $0`;

    }

    // if (name[13].groups[0].isVisible && name[13].groups[0].fields[1].default !== undefined) {
    //     name[13].groups[3].isVisible = true;
    // } else {
    //     name[13].groups[3].isVisible = false;
    // }
};

const showhosCbRsrchOffSetSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    medicalcostCal2(name);

};
const medicalcostCal2 = (name) => {
    const hosCbRsrchRev = parseFloat(name[13].groups[2].fields[1].default);

    const hosCbRsrchOffSetSubTtl = `Direct Offsetting Revenue \n ${amountCurrencyFormatter(hosCbRsrchRev)}`;
    if (name[13].groups[0].fields[1].default === EVENT.KEY_YES && name[13].groups[2].fields[1].default === undefined) {
        name[13].groups[3].fields[2].default = `Direct Offsetting Revenue \n $0`
    } else {
        name[13].groups[3].fields[2].default = hosCbRsrchOffSetSubTtl
    }
}

const showhosCbRsrchCostsCalcSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    medicalcostCal1(name);
};

const medicalcostCal1 = (name) => {
    const hosCbRsrchCosts = parseFloat(name[13].groups[1].fields[1].default);

    const hosCbRsrchCostsCalcSubTtl = `Total Benefit Expense \n ${amountCurrencyFormatter(hosCbRsrchCosts)}`;
    if (name[13].groups[0].fields[1].default === EVENT.KEY_YES && name[13].groups[1].fields[1].default === undefined) {
        name[13].groups[3].fields[1].default = `Total Benefit Expense \n $0`
    } else {
        name[13].groups[3].fields[1].default = hosCbRsrchCostsCalcSubTtl
    }
}

const showhosCbInKindCashCont = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (name[14].groups[0].fields[1].default === EVENT.KEY_YES && name[14].groups[0].isVisible) {
        name[14].groups[0].hasDivider = true;
        name[14].groups[1].isVisible = true;
        name[14].groups[2].isVisible = true;
        cashcontribCal1(name);
        cashcontribCal2(name);
    } else {
        name[14].groups[1].isVisible = false;
        name[14].groups[2].isVisible = false;
        name[14].groups[0].hasDivider = false;
        name[14].groups[3].fields[1].default = `Total Benefit Expense \n $0`;
        name[14].groups[3].fields[2].default = `Direct Offsetting Revenue \n $0`;
    }
    // if (name[14].groups[0].isVisible && name[14].groups[0].fields[1].default !== undefined) {
    //     name[14].groups[3].isVisible = true;
    // } else {
    //     name[14].groups[3].isVisible = false;
    // }
};

const showhosCbInKindCostsCalcSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    cashcontribCal1(name);
};

const cashcontribCal1 = (name) => {
    const hosCbInKindCashContAmt = parseFloat(name[14].groups[1].fields[1].default);

    const showhosCbInKindCostsCalcSubTtl = `Total Benefit Expense \n ${amountCurrencyFormatter(hosCbInKindCashContAmt)}`;
    if (name[14].groups[0].fields[1].default === EVENT.KEY_YES && name[14].groups[1].fields[1].default === undefined) {
        name[14].groups[3].fields[1].default = `Total Benefit Expense \n $0`
    }
    else {
        name[14].groups[3].fields[1].default = showhosCbInKindCostsCalcSubTtl
    }
}
const cashcontribCal2 = (name) => {
    const hosCbInKindRevOffCont = parseFloat(name[14].groups[2].fields[1].default);

    const showhosCbInKindOffSetSubTtl = `Direct Offsetting Revenue \n ${amountCurrencyFormatter(hosCbInKindRevOffCont)}`;
    if (name[14].groups[0].fields[1].default === EVENT.KEY_YES && name[14].groups[2].fields[1].default === undefined) {
        name[14].groups[3].fields[2].default = `Direct Offsetting Revenue \n $0`
    } else {
        name[14].groups[3].fields[2].default = showhosCbInKindOffSetSubTtl
    }
}
const showhosCbInKindOffSetSubTtl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    cashcontribCal2(name);
};

const show501rPoliciesPracticesSec = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (name[0].groups[2].fields[1].default === EVENT.KEY_NO) {
        name[15].isVisible = true;
    } else {
        name[15].isVisible = false;
    }
}

const showHosCbTblNonCostChrgMthdDesc = (event , options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_YES) {
        name[6].groups[3].isVisible = true;
    } else {
        name[6].groups[3].isVisible = false;
    }
}


const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'showHosCbAttachRptUpld':
            showHosCbAttachRptUpld(event, options);
            break;
        case 'showCommunityBuildingAct':
            showCommunityBuildingAct(event, options);
            break;
        case 'showMedicareRevenueCosts':
            showMedicareRevenueCosts(event, options);
            break;
        case 'showhosDbtMcRevMthdOther':
            showhosDbtMcRevMthdOther(event, options);
            break;
        case 'showWrittenDebtCollectionPolicy':
            showWrittenDebtCollectionPolicy(event, options);
            break;
        case 'showHosFaPolAppDsc':
            showHosFaPolAppDsc(event, options);
            break;
        case 'showHosFaIncmLmtFree':
            showHosFaIncmLmtFree(event, options);
            break;
        case 'showHosFaIncmLmtDisc':
            showHosFaIncmLmtDisc(event, options);
            break;
        case 'showHosFaComServDesc':
            showHosFaComServDesc(event, options);
            break;
        case 'showHosFaAffilSysRole':
            showHosFaAffilSysRole(event, options);
            break;
        case 'totalBenefitExpenseCal':
            totalBenefitExpenseCal(event, options);
            break;
        case 'showAcquisitionDetails':
            showAcquisitionDetails(event, options);
            break;
        case 'calculateLastCHNA':
            calculateLastCHNA(event, options);
            break;
        case 'showHosCbCharOffTaxAmt':
            showHosCbCharOffTaxAmt(event, options);
            break;
        case 'showHosCbCharCarePoolRevRcv':
            showHosCbCharCarePoolRevRcv(event, options);
            break;
        case 'showHosCbCharCostsCalcSubTtl':
            showHosCbCharCostsCalcSubTtl(event, options);
            break;
        case 'showHospCbCharOffSetSubTtl':
            showHospCbCharOffSetSubTtl(event, options);
            break;
        case 'showCommunityBenefitReports':
            showCommunityBenefitReports(event, options);
            break;
        case 'showhosFacAddInfo':
            showhosFacAddInfo(event, options);
            break;
        case 'showHosFacAddDocsAttach':
            showHosFacAddDocsAttach(event, options);
            break;
        case 'showhosBillInitOthDescLabel':
            showhosBillInitOthDescLabel(event, options);
            break;
        case 'showhosBillTknOtherDescLabel':
            showhosBillTknOtherDescLabel(event, options);
            break;
        case 'showhosBillPermOtherDescLabel':
            showhosBillPermOtherDescLabel(event, options);
            break;
        case 'showhosChrgMoreInsCovExp':
            showhosChrgMoreInsCovExp(event, options);
            break;
        case 'showhosChrgEqlGrossChrgExp':
            showhosChrgEqlGrossChrgExp(event, options);
            break;
        case 'showhosEmgcLmtdEligDesc':
            showhosEmgcLmtdEligDesc(event, options);
            break;
        case 'showHosEmgcOthDescLabel':
            showHosEmgcOthDescLabel(event, options);
            break;
        case 'showHosFapOthIncmLvlDesc':
            showHosFapOthIncmLvlDesc(event, options);
            break;
        case 'showHosFapOthDesc':
            showHosFapOthDesc(event, options);
            break;
        case 'showHosFapFinAsstMthLabel11':
            showHosFapFinAsstMthLabel11(event, options);
            break;
        case 'showHosFapOtherAssistDesc':
            showHosFapOtherAssistDesc(event, options);
            break;
        case 'showHosFapWrtMeasPub1':
            showHosFapWrtMeasPub1(event, options);
            break;
        case 'showHosFapWebAdd':
            showHosFapWebAdd(event, options);
            break;
        case 'showHosFapAppWebAdd':
            showHosFapAppWebAdd(event, options);
            break;
        case 'showHosFapPlainLangWebAdd':
            showHosFapPlainLangWebAdd(event, options);
            break;
        case 'showhosEmgcWtnPolLabel4':
            showhosEmgcWtnPolLabel4(event, options);
            break;
        case 'showHosCbComHlthNdsAssessExp':
            showHosCbComHlthNdsAssessExp(event, options);
            break;
        case 'showHosCbComHlthSubPrgrmExp':
            showHosCbComHlthSubPrgrmExp(event, options);
            break;
        case 'showHosCbComHlthComBnftExp':
            showHosCbComHlthComBnftExp(event, options);
            break;
        case 'showHosCbComHlthFundExp':
            showHosCbComHlthFundExp(event, options);
            break;
        case 'showHosFapOthPubDesc':
            showHosFapOthPubDesc(event, options);
            break;
        case 'showhosCbMaProvTaxAmt':
            showhosCbMaProvTaxAmt(event, options);
            break;
        case 'showhosCbMaCostsCalcSubTtl':
            showhosCbMaCostsCalcSubTtl(event, options);
            break;
        case 'showhosCbMaOffSetSubTtl':
            showhosCbMaOffSetSubTtl(event, options);
            break;
        case 'showhosCbMaNetPatRevAmtt':
            showhosCbMaNetPatRevAmtt(event, options);
            break;
        case 'showhosCbMaCarePoolPayAmt':
            showhosCbMaCarePoolPayAmt(event, options);
            break;
        case 'showhosCbMaOthRevAmt':
            showhosCbMaOthRevAmt(event, options);
            break;
        case 'showHosCbOthMnsTaxAmt':
            showHosCbOthMnsTaxAmt(event, options);
            break;
        case 'showHosCbOthMnsNetPatRevAmt':
            showHosCbOthMnsNetPatRevAmt(event, options);
            break;
        case 'showHosCbOthMnsCarePoolPayAmt':
            showHosCbOthMnsCarePoolPayAmt(event, options);
            break;
        case 'showHosCbOthMnsOthRevAmt':
            showHosCbOthMnsOthRevAmt(event, options);
            break;
        case 'showHosCbOthMnsOffSetSubTtl':
            showHosCbOthMnsOffSetSubTtl(event, options);
            break;
        case 'calculateHosCbOthMnsCostsCalcSubTtl':
            calculateHosCbOthMnsCostsCalcSubTtl(event, options);
            break;
        case 'showHosCbProExpenses':
            showHosCbProExpenses(event, options);
            break;
        case 'showHosCbProEdOffSetSubTtl':
            showHosCbProEdOffSetSubTtl(event, options);
            break;
        case 'calculateHosCbProEdCostsCalcSubTtl':
            calculateHosCbProEdCostsCalcSubTtl(event, options);
            break;
        case 'showhosCbSubOffSetSubTtl':
            showhosCbSubOffSetSubTtl(event, options);
            break;
        case 'showhosCbSubCostsCalcSubTtl':
            showhosCbSubCostsCalcSubTtl(event, options);
            break;
        case 'showhosCbSubLoss':
            showhosCbSubLoss(event, options);
            break;
        case 'showhosCbRsrchFund':
            showhosCbRsrchFund(event, options);
            break;
        case 'showhosCbRsrchOffSetSubTtl':
            showhosCbRsrchOffSetSubTtl(event, options);
            break;
        case 'showhosCbRsrchCostsCalcSubTtl':
            showhosCbRsrchCostsCalcSubTtl(event, options);
            break;
        case 'showhosCbInKindCashCont':
            showhosCbInKindCashCont(event, options);
            break;
        case 'showhosCbInKindCostsCalcSubTtl':
            showhosCbInKindCostsCalcSubTtl(event, options);
            break;
        case 'showhosCbInKindOffSetSubTtl':
            showhosCbInKindOffSetSubTtl(event, options);
            break;
        case 'show501rPoliciesPracticesSec':
            show501rPoliciesPracticesSec(event, options);
            break;
        case 'showHospitalStateProv':
            showHospitalStateProv(event, options);
            break;
        case 'showNonHospitalStateProv':
            showNonHospitalStateProv(event, options);
            break;
        case 'showhosFacHosType8Desc':
            showhosFacHosType8Desc(event, options);
            break;
        case 'showNonHospitalGroup':
            showNonHospitalGroup(event, options);
            break;
        case 'setHospitalName':
            setHospitalName(event, options);
            break;
        case 'showHosBillPermNone':
            showHosBillPermNone(event, options);
            break;
        case 'showHosBillTknNone':
            showHosBillTknNone(event, options);
            break;
        case 'showHosBillInitNone':
            showHosBillInitNone(event, options);
            break;
        case 'showHosBillPermNoneDisOthr':
            showHosBillPermNoneDisOthr(event, options);
            break;
        case 'showHosBillTknNoneDisOthr':
            showHosBillTknNoneDisOthr(event, options);
            break;
        case 'showHosBillInitNoneDisOthr':
            showHosBillInitNoneDisOthr(event, options);
            break;
        case 'showHosCbTblNonCostChrgMthdDesc':
            showHosCbTblNonCostChrgMthdDesc(event, options);
            break;
        case 'showhosFaIncmLmtFreeOth':
            showhosFaIncmLmtFreeOth(event, options);
            break;
        case 'showHosFaIncmLmtDiscOth':
            showHosFaIncmLmtDiscOth(event, options);
            break;
        case 'showhosChnaYrAdopt':
            showhosChnaYrAdopt(event, options);
            break;
        case 'showhosChnaImpStratAvailWebAdd':
            showhosChnaImpStratAvailWebAdd(event, options);
            break;
        case 'showhosChnaOtherDesc':
            showhosChnaOtherDesc(event, options);
            break;
        case 'showhosChnaSpecKnow':
            showhosChnaSpecKnow(event, options);
            break;
        case 'showhosChnaMany':
            showhosChnaMany(event, options);
            break;
        case 'showhosChnaManyNonHspt':
            showhosChnaManyNonHspt(event, options);
            break;
        case 'showhosChnaPublicAvail':
            showhosChnaPublicAvail(event, options);
            break;
        case 'showhosChnaHosWeb':
            showhosChnaHosWeb(event, options);
            break;
        case 'showhosChnaOthWeb':
            showhosChnaOthWeb(event, options);
            break;
        case 'showhosChnaOther1Desc':
            showhosChnaOther1Desc(event, options);
            break;
        default:
            break;
    }
};


const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        case 'calculateSummaryCHNA':
            calculateSummaryCHNA(options);
            break;
        case 'showHospitalNameInSummary':
            showHospitalNameInSummary(options);
            break;
        case 'showHospitalStateProv':
            showHospitalStateProv(options);
            break;
        case 'setFapDetails':
            setFapDetails(options);
            break;
        case 'showNonHospitalNameInSummary':
            showNonHospitalNameInSummary(options)
            break;
        case 'showNumberOfHospital':
            showNumberOfHospital(options);
            break;
        case 'removeHospitalSection':
            removeHospitalSection(options);
            break;
        case 'calculateSummaryError':
            calculateSummaryError(options);
            break;
        default:
            break;
    }
}

export {
    triggeredEvent,
    sectionFieldLogic
};